import React, { useState, useEffect } from "react";
import "./ClientAdd.css";
import {
  Cities,
  Industries,
  Month_numbers,
  Months,
} from "../../../App/services/services";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faUserPlus,
  faCheckCircle,
  faTimesCircle,
  faSpinner,
} from "@fortawesome/free-solid-svg-icons";
import { useLocation, useHistory } from "react-router-dom";
import { useFormik } from "formik";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import Cookies from "universal-cookie";
import { CountryDropdown } from "react-country-region-selector";
import { Prompt } from "react-router";
import { url_crm_clients } from "../../../CRM/constants/constants";

function ClientAdd(props) {
  const location = useLocation();
  const json_response = location.state.state.json_response;

  const history = useHistory();

  //   const json_response = props.location.state;
  const cookies = new Cookies();
  const token = cookies.get("token_crm");
  const codigo_vendedor = cookies.get("codigo_vendedor");
  const super_user = cookies.get("super_user");
  const [responseVendors, setResponseVendors] = useState();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [typePerson, setTypePerson] = useState(json_response.tipo_persona);
  const [countryClient, setCountryClient] = useState("Colombia");
  const [countryContact, setCountryContact] = useState("Colombia");

  const [nit, setNit] = useState(json_response.nroid_cliente);
  const [dv, setDv] = useState("");

  const [edit, setEdit] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [openDialog2, setOpenDialog2] = useState(false);
  const [openDialog3, setOpenDialog3] = useState(false);
  const [fetchSuccess, setFetchSuccess] = useState(false);

  const [shouldBlockNavigation, setBlock] = useState(false);

  const getDV = (event) => {
    setNit(event.target.value);
    var myNit = event.target.value;
    var dv = 0;
    var vpri, x, y, z;

    // Se limpia el Nit
    myNit = myNit.replace(/\s/g, ""); // Espacios
    myNit = myNit.replace(/,/g, ""); // Comas
    myNit = myNit.replace(/\./g, ""); // Puntos
    myNit = myNit.replace(/-/g, ""); // Guiones

    // Se valida el nit
    if (isNaN(myNit)) {
      console.log("El nit/cédula '" + myNit + "' no es válido(a).");
      return "";
    }

    // Procedimiento
    vpri = new Array(16);
    z = myNit.length;
    // console.log(z)
    vpri[1] = 3;
    vpri[2] = 7;
    vpri[3] = 13;
    vpri[4] = 17;
    vpri[5] = 19;
    vpri[6] = 23;
    vpri[7] = 29;
    vpri[8] = 37;
    vpri[9] = 41;
    vpri[10] = 43;
    vpri[11] = 47;
    vpri[12] = 53;
    vpri[13] = 59;
    vpri[14] = 67;
    vpri[15] = 71;

    x = 0;
    y = 0;
    for (var i = 0; i < z; i++) {
      y = myNit.substr(i, 1);
      // console.log ( y + "x" + vpri[z-i] + ":" ) ;

      x += y * vpri[z - i];
      // console.log ( x ) ;
    }

    y = x % 11;
    // console.log ( y ) ;

    dv = y > 1 ? 11 - y : y;
    if (z >= 9) {
      setDv(dv);
    }
  };

  const getDV2 = (event) => {
    setNit(event);
    var myNit = event;
    var dv = 0;
    var vpri, x, y, z;

    // Se limpia el Nit
    // myNit = myNit.replace(/\s/g, ""); // Espacios
    // myNit = myNit.replace(/,/g, ""); // Comas
    // myNit = myNit.replace(/\./g, ""); // Puntos
    // myNit = myNit.replace(/-/g, ""); // Guiones

    // Se valida el nit
    if (isNaN(myNit)) {
      console.log("El nit/cédula '" + myNit + "' no es válido(a).");
      return "";
    }

    // Procedimiento
    vpri = new Array(16);
    z = myNit.length;
    // console.log(z)
    vpri[1] = 3;
    vpri[2] = 7;
    vpri[3] = 13;
    vpri[4] = 17;
    vpri[5] = 19;
    vpri[6] = 23;
    vpri[7] = 29;
    vpri[8] = 37;
    vpri[9] = 41;
    vpri[10] = 43;
    vpri[11] = 47;
    vpri[12] = 53;
    vpri[13] = 59;
    vpri[14] = 67;
    vpri[15] = 71;

    x = 0;
    y = 0;
    for (var i = 0; i < z; i++) {
      y = myNit.substr(i, 1);
      // console.log ( y + "x" + vpri[z-i] + ":" ) ;

      x += y * vpri[z - i];
      // console.log ( x ) ;
    }

    y = x % 11;
    // console.log ( y ) ;

    dv = y > 1 ? 11 - y : y;
    console.log(dv);
    setDv(dv);
    if (z >= 9) {
      setDv(dv);
    }
  };

  const doSomething = () => {
    var input;
    input = window.confirm("Desea salir sin guardar los cambios?");
    global.cancel_rut = input;

    return input;
  };
  useEffect(() => {
    if (shouldBlockNavigation) {
      window.onbeforeunload = () => true;
    } else {
      window.onbeforeunload = undefined;
    }

    fetch(`${global.url_back}/get_code_vendors/`, {
      method: "GET",
      headers: {
        Authorization: `Token ${token}`,
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        if (response.status === 200) {
          return response.json().then((json_response) => {
            setResponseVendors(json_response);
            console.log(nit, "Aqui esta");
            getDV2(nit);
          });
        } else {
          console.log("error UserConfig");
          setFetchSuccess(false);
          setOpenDialog(true);
        }
      })
      .catch((error) => {
        console.log(error);
        setFetchSuccess(false);
        setOpenDialog(true);
      });
  }, []);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      pk: json_response?.pk,
      razonSocial: json_response?.nombre_cliente,
      codigo_back: json_response?.codigo_vendedor.codigo_vendedor,
      ciudad: json_response?.ciudad,
      direccion: json_response?.direccion,
      ind: json_response?.indic_telefono,
      telefono: json_response?.telefono,
      canalContacto: json_response?.canal_contacto,
      comoConocio: json_response?.como_conocio,
      sector: json_response?.sector,
      nombreComercial: json_response?.nombre_comercial,
      identificacion: json_response?.nroid_cliente,
    },
    onSubmit: (values) => {
      //   alert(JSON.stringify(values, null, 2));
      setBlock(false);
      setIsSubmitting(true);
      if (
        values.codigo_back === parseInt(codigo_vendedor) ||
        super_user === "1"
      ) {
        fetch(`${global.url_back}/uptdate_client/`, {
          method: "PUT",
          headers: {
            Authorization: `Token ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            values: values,
            typePerson: typePerson,
            countryClient: countryClient,
            nit: nit,
          }),
        })
          .then((response) => {
            if (response.status === 200) {
              setIsSubmitting(false);
              setFetchSuccess(true);
              setOpenDialog(true);
            } else if (response.status === 409) {
              setIsSubmitting(false);
              setFetchSuccess(false);
              setOpenDialog3(true);
              console.log("error Cliente ya existe");
            } else {
              setIsSubmitting(false);
              setFetchSuccess(false);
              setOpenDialog(true);
              console.log("error fetchingform-clientAdd");
            }
          })
          .catch((error) => {
            setIsSubmitting(false);
            setFetchSuccess(false);
            setOpenDialog(true);
            console.log(error);
          });
      } else {
        setIsSubmitting(false);
        setOpenDialog2(true);
      }
    },
  });

  return (
    <div className="container__clientadd">
      <Dialog
        onClose={() => {
          setOpenDialog2(false);
        }}
        aria-labelledby="simple-dialog-title"
        open={openDialog2}
      >
        <DialogTitle id="simple-dialog-title">
          <FontAwesomeIcon icon={faTimesCircle} size="2x" color="red" />
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Este cliente no está asignado a tu código, por tanto no puedes
            modificarlo
          </DialogContentText>
        </DialogContent>
      </Dialog>
      <Dialog
        onClose={() => {
          setOpenDialog(false);
          if (fetchSuccess) {
            history.push(url_crm_clients);
          }
        }}
        aria-labelledby="simple-dialog-title"
        open={openDialog}
      >
        <DialogTitle id="simple-dialog-title">
          {fetchSuccess ? (
            <FontAwesomeIcon icon={faCheckCircle} size="2x" />
          ) : (
            <FontAwesomeIcon icon={faTimesCircle} size="2x" color="red" />
          )}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            {fetchSuccess
              ? "Cliente actualizado con exito"
              : "Algo salio mal, intenta nuevamente"}
          </DialogContentText>
        </DialogContent>
      </Dialog>
      <Dialog
        onClose={() => {
          setOpenDialog3(false);
        }}
        aria-labelledby="simple-dialog-title"
        open={openDialog3}
      >
        <DialogTitle id="simple-dialog-title">
          <FontAwesomeIcon icon={faTimesCircle} size="2x" color="red" />
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            El NIT ya se encuentra registrado.
          </DialogContentText>
        </DialogContent>
      </Dialog>
      <img
        src={require("../../../App/images/fabrica.svg")}
        alt=""
        className="img__factory__addclient"
      />
      <h4>Clientes</h4>
      <p style={{ marginBottom: "10px", fontWeight: "bold" }}>
        Tipo de Persona
      </p>
      <div className="clienteadd_radiobutton">
        <input
          className="inputRadio__addclient"
          type="radio"
          id="Natural"
          name="tipoPersona"
          value={typePerson}
          checked={typePerson === "Natural"}
          onClick={() => setTypePerson("Natural")}
          disabled={json_response.tipo_persona === "Natural" ? false : true}
        />
        <label for="contactChoice1">Natural</label>
        <input
          className="inputRadio__addclient"
          type="radio"
          id="Juridica"
          name="tipoPersona"
          value={typePerson}
          checked={typePerson === "Juridica"}
          onClick={() => setTypePerson("Juridica")}
          disabled={json_response.tipo_persona === "Juridica" ? false : true}
        />
        <label for="contactChoice1">Juridica</label>
      </div>
      <form onSubmit={formik.handleSubmit}>
        <h4 style={{ margin: "15px 0" }}>Informacion Compañia</h4>
        <div className="flex_razon_codigo">
          <div className="razon_social">
            <p className="labeltag">
              {typePerson === "Juridica"
                ? "Razon Social"
                : "Nombres y Apellidos"}{" "}
              *
            </p>
            <input
              type="text"
              style={{ width: "100%" }}
              name="razonSocial"
              onChange={formik.handleChange}
              value={formik.values.razonSocial}
              required
              disabled={edit ? false : true}
            />
          </div>

          <div className="code">
            <p className="labeltag">Codigo *</p>
            <select
              name="codigo_back"
              onChange={formik.handleChange}
              value={formik.values.codigo_back}
              disabled={super_user === "0" ? true : false}
            >
              {responseVendors?.map((codigo) => (
                <option value={codigo}>{codigo}</option>
              ))}
            </select>
          </div>
        </div>
        <div className="flex_other_info">
          <div className="column1__clientadd" style={{ marginRight: "15%" }}>
            {typePerson === "Juridica" ? (
              <div className="flex_double_input">
                <div className="nit">
                  <p className="labeltag">Nit *</p>
                  <input
                    type="text"
                    className="input__info "
                    onChange={(event) => getDV(event)}
                    value={nit}
                    disabled={edit ? false : true}
                  />
                </div>
                <div className="dv">
                  <p className="labeltag">DV</p>
                  <input
                    type="text"
                    className="input__info nit_input"
                    value={dv}
                    disabled
                  />
                </div>
              </div>
            ) : (
              <div className="input_tag_addclient">
                <p className="labeltag">Cedula</p>
                <input
                  type="text"
                  className="input__info full-width"
                  name="identificacion"
                  onChange={formik.handleChange}
                  value={formik.values.identificacion}
                  disabled={edit ? false : true}
                />
              </div>
            )}
            <div className="input_tag_addclient">
              <p className="labeltag">Pais *</p>
              <CountryDropdown
                value={countryClient}
                onChange={(val) => setCountryClient(val)}
                style={{ marginBottom: "15px" }}
                disabled={edit ? false : true}
              />
            </div>
            <div className="input_tag_addclient">
              <p className="labeltag">Ciudad *</p>
              <input
                required
                type="text"
                className="input__info"
                list="ciudades"
                name="ciudad"
                onChange={formik.handleChange}
                value={formik.values.ciudad}
                required
                disabled={edit ? false : true}
              />
              {countryClient === "Colombia" ? <Cities id="ciudades" /> : null}
            </div>
            <div className="input_tag_addclient">
              <p className="labeltag">Direccion</p>
              <input
                type="text"
                className="input__info full-width"
                name="direccion"
                onChange={formik.handleChange}
                value={formik.values.direccion}
                disabled={edit ? false : true}
              />
            </div>
            <div className="flex_double_input">
              <div className="ind">
                <p className="labeltag">Ind.</p>
                <input
                  type="number"
                  className="input__info ind__input"
                  name="ind"
                  onChange={formik.handleChange}
                  value={formik.values.ind}
                  disabled={edit ? false : true}
                />
              </div>
              <div className="nit">
                <p className="labeltag">Telefono</p>
                <input
                  type="number"
                  className="input__info"
                  name="telefono"
                  onChange={formik.handleChange}
                  value={formik.values.telefono}
                  disabled={edit ? false : true}
                />
              </div>
            </div>
          </div>
          <div className="column1__clientadd">
            {typePerson == "Juridica" ? null : (
              <div className="input_tag_addclient">
                <p className="labeltag">Nombre Comercial</p>
                <input
                  type="text"
                  className="input__info full-width"
                  name="nombreComercial"
                  onChange={formik.handleChange}
                  value={formik.values.nombreComercial}
                  disabled={edit ? false : true}
                />
              </div>
            )}
            <div className="input_tag_addclient">
              <p className="labeltag">Canal de Contacto *</p>
              <select
                name="canalContacto"
                onChange={formik.handleChange}
                value={formik.values.canalContacto}
                className="select__addclient"
                required
                disabled={edit ? false : true}
              >
                <option value="">-</option>
                <option value="Telefono">Telefono</option>
                <option value="WhatsApp">WhatsApp</option>
                <option value="Correo">Correo</option>
                <option value="Web">Web</option>
                <option value="Personal">Personal</option>
                <option value="Redes">Redes</option>
              </select>
            </div>
            <div className="input_tag_addclient">
              <p className="labeltag">Como nos conocio?</p>
              <select
                name="comoConocio"
                onChange={formik.handleChange}
                value={formik.values.comoConocio}
                className="select__addclient"
                disabled={edit ? false : true}
              >
                <option value="">-</option>
                <option value="Web">Web</option>
                <option value="Redes">Redes</option>
                <option value="Referido">Referido</option>
                <option value="Feria">Feria</option>
              </select>
            </div>
            <div className="input_tag_addclient">
              <p className="labeltag">Sector *</p>
              <select
                name="sector"
                onChange={formik.handleChange}
                value={formik.values.sector}
                className="select__addclient"
                disabled={edit ? false : true}
              >
                <Industries />
              </select>
            </div>
            {edit ? (
              <button
                className="btn-userconfig"
                type="submit"
                style={{ margin: "20px 0 0 auto" }}
              >
                {isSubmitting ? (
                  <FontAwesomeIcon
                    icon={faSpinner}
                    className="fa-spin"
                    style={{ fontSize: "25px" }}
                  />
                ) : (
                  "Guardar"
                )}
              </button>
            ) : null}
            {edit ? null : (
              <button
                type="button"
                className="btn-userconfig"
                style={{ margin: "20px 0 0 auto" }}
                onClick={() => {
                  setBlock(true);

                  if (shouldBlockNavigation) {
                    window.onbeforeunload = undefined;
                  } else {
                    window.onbeforeunload = () => true;
                  }

                  setEdit(true);
                }}
              >
                Editar
              </button>
            )}
          </div>
        </div>
      </form>
      <Prompt
        when={edit}
        message={(location, action) => {
          if (action === "PUSH") {
          }

          return location.pathname.startsWith("/app") ? true : doSomething();
        }}
      />
    </div>
  );
}

export default ClientAdd;
